import { Link } from "gatsby"
import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../../../../utility/utils"

const ComplaintsMobile = () => {
    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])
    return (
        <Fragment>
            <div className="kuda-inner--heading kuda-disclaimer--section">
                <div className="kuda-section--inner general-style">
                    <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
                        <Link to="/mobile/uk/legal/" className="color-black mb-3 mt-4 back-page">
                            <div className="align-self-center mr-2">
                                <div className="f-14">
                                    Legal
                                </div>
                            </div>
                            <span className="mr-2">
                                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                        fill="#C7C7CC"
                                    ></path>
                                </svg>
                            </span>
                            <span className="align-self-center f-14 mr-2 text-left">United Kingdom</span>
                            <span className="mr-2">
                                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                        fill="#C7C7CC"
                                    ></path>
                                </svg>
                            </span>
                            <span className="align-self-center f-14 text-bold text-left">Complaints</span>
                        </Link>
                        <div className="legal-left">
                            <h1 className="kuda-section--heading text-lg-biz text-xlbold color-black title-bottom--spacing no-margins">
                                Complaints
                            </h1>
                        </div>
                    </div>

                    <div className="kuda-section--100 kuda-spacing--inner">

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <h1 className="kuda-section--heading text-left text-xlbold color-black title-bottom--spacing">
                                 A guide to Making a Complaint
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    At Kuda UK we aim to provide great products and excellent service to our customers. However, we know that sometimes things can go wrong and that you may have cause to complain. If you have a complaint, then please let us know as soon as possible.
                                </p>
                                <p className="color-black bottom--spacing">
                                    We will do our very best to resolve your complaint as soon as you get in touch with us:
                                </p>
                                <p className="color-black bottom--spacing">
                                    By email: {" "}
                                    <a
                                        href="mailto:complaintuk@kuda.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="color-black text-bold link-decoration cursor-pointer"
                                    >
                                        complaintuk@kuda.com
                                    </a>
                                </p>
                                <p className="color-black bottom--spacing">
                                    By post: Complaint Manager, Kuda EMI Limited, 5 New Street Square, London, United Kingdom, EC4A 3TW
                                </p>
                            </div>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <h1 className="kuda-section--heading text-left text-xlbold color-black title-bottom--spacing">
                                Investigating your complaint
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    We aim to resolve your complaint within 3 working days following the receipt of your complaint. Once an agreed resolution has been reached, we will confirm details in writing to you in a final resolution letter.
                                </p>
                                <p className="color-black bottom--spacing">
                                    However, there may be occasions when it will take longer to respond to your complaint. If this happens, we will send you an acknowledgement within 5 business days. This acknowledgement will include the name and contact details of the person handling your complaint, this may not be the same person who received your complaint.
                                </p>
                                <p className="color-black bottom--spacing">
                                    In the unlikely event that we have not resolved your complaint within four weeks we will write to you to advise the reason why and when we expect to resolve it. We aim to send you a final response within 35 working days of your original complaint.
                                </p>
                            </div>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <h1 className="kuda-section--heading text-left text-xlbold color-black title-bottom--spacing">
                                The Financial Ombudsman Service (FOS)
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    The FOS is an independent complaint resolution service that was set up to resolve complaints between financial businesses and their customers.
                                </p>
                                <p className="color-black bottom--spacing">
                                    If you are dissatisfied with our response to your complaint, you have the right to contact the FOS, free of charge, but you must do so within six months of the date of our final response letter. If you do not refer your complaint in time, the FOS will not have our permission to consider your complaint and so will only be able to do so in very limited circumstances.
                                </p>
                                <p className="color-black bottom--spacing">
                                    The FOS contact details are:
                                </p>
                                <p className="color-black bottom--spacing">
                                    By telephone: Freephone number is 0800 023 4567 or 0300 123 9123 from a mobile
                                </p>
                                <p className="color-black bottom--spacing">
                                    By email: {" "}
                                    <a
                                        href="mailto:complaint.info@financial-ombudsman.org.uk"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="color-black text-bold link-decoration cursor-pointer"
                                    >
                                        complaint.info@financial-ombudsman.org.uk
                                    </a>
                                </p>
                                <p className="color-black bottom--spacing">
                                    By Post: The Financial Ombudsman Service
                                </p>
                                <p className="color-black bottom--spacing">
                                    Exchange Tower
                                </p>
                                <p className="color-black bottom--spacing">
                                    London
                                </p>
                                <p className="color-black bottom--spacing">
                                    E14 9SR
                                </p>
                                <p className="color-black bottom--spacing">
                                    Visit their website: {" "}
                                    <a
                                        href="https://www.financial-ombudsman.org.uk/"
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                        className="color-black text-bold link-decoration cursor-pointer"
                                    >
                                        https://www.financial-ombudsman.org.uk
                                    </a>
                                </p>
                                <p className="color-black bottom--spacing">
                                    You must refer your complaint to the Financial Ombudsman within 6 months of the date on the final response.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default ComplaintsMobile
